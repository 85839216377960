import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		cid: '',
		slot1: '',
		slot2: '',
		slot3: '',
		data_ad_client: '',
		rootDomain:'',
	},
	mutations: {
		UPDATE_CID: (state, txt) => {
			state.cid = txt
		},
		UPDATE_SLOT1: (state, txt) => {
			state.slot1 = txt
		},
		UPDATE_SLOT2: (state, txt) => {
			state.slot2 = txt
		},
		UPDATE_SLOT3: (state, txt) => {
			state.slot3 = txt
		},
		UPDATE_DATA_AD_CLIENT: (state, txt) => {
			state.data_ad_client = txt
		},
		UPDATE_ROOTDOMAIN: (state, txt) => {
			state.rootDomain = txt
		}
	},

})

export default store