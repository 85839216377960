import Vue from 'vue'
import App from './App.vue'
import router from '@/router/news'
import global from '@/utlis/global.js'
import store from '@/store'
import '@/utlis/remcommon.js'
import VueLazyload from 'vue-lazyload'
import { initializeApp} from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
Vue.config.productionTip = false
Vue.prototype.$global = global
// const loadimage = require('@/assets/news/loading.png')
Vue.prototype.$api = global.api
import '../../utlis/vant'
// Vue.use(Meta)
// Vue.use(VueLazyload, {
// 	preLoad: 1.3,
// 	error: loadimage,
// 	loading: loadimage,
// 	attempt: 1,
// })
const firebaseConfig =  {
  apiKey: "AIzaSyA_deiO3J03dr2s-iuvGUCv6HMHFqduIPc",
  authDomain: "news-h5.firebaseapp.com",
  projectId: "news-h5",
  storageBucket: "news-h5.appspot.com",
  messagingSenderId: "543685424336",
  appId: "1:543685424336:web:18ce043e12a4e63f9b9ad5",
  measurementId: "G-Z5DRRRNVT6"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
Vue.prototype.$logEvent = (event, params) => {
  logEvent(analytics, event, params);
};
Vue.prototype.$eventrackFb = (msg, method,map = {}) => {
  let params = {
    time: new Date(),
    message: msg,
     method: method,
    ...map,
  };
  logEvent(analytics, msg, params);
};
new Vue({                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
  router,
  store,
  render: h => h(App),
}).$mount('#app')
