<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

import '@/css/include/hhrest.scss'
import '@/css/news/index.scss'
export default {
  name: "home",
  data() {

  },
  mounted() {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${this.$store.state.data_ad_client}`
    document.head.appendChild(script);
  },
  methods: {


  }
}

</script>

  